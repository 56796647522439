import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image3 from "../Images/03hero.jpg";
const About = () => {
  return (
    <>
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="align-items-center vh-10">
          <Col md={5}>
            <img src={image3} alt="Placeholder" className="img-fluid" />
          </Col>
          <Col md={{ span: 6, offset: 1 }}>
            <p>
              Zak Mulligan is a cinematographer known for his work in film and
              television. His film We The Animals premiered at Sundance 2018 and
              was nominated for five independent spirit Awards including Best
              Cinematography. His other work includes the HBO series The
              Outsider based on a novel by Stephen King starring Jason Bateman
              and Ben Mendelsohn and the film obselidia which premiered at
              Sundance 2010 where he was honored with the Excellence in
              cinematography award. His latest film, Hustle, staring Adam
              Sandler sees Muligan paired again with Director Jeremiah Zagar.
              Motion Picture Arts and Science (AMPAS) in 2019. <br />
              <br />
              Zak Mulligan is a cinematographer known for his work in film and
              television. His film We The Animals premiered at Sundance 2018 and
              was nominated for five independent spirit Awards including Best
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
