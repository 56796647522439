import React from "react";
import ImageGridSlider from "./ImageGridSlider";

const Portfolio = () => {
  return (
    <>
      <ImageGridSlider />
    </>
  );
};

export default Portfolio;
