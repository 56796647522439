import logo from './Images/logo.png';
import ReactDOM from "react-dom/client";
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Container, Nav,Navbar } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Main from './Components/Main';

import Portfolio from './Components/Portfolio';
import About from './Components/About';

import Contact from './Components/Contact';
import Page from './Components/Page';
import PageOne from './Components/Page-1';
import { Modal } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';  // Using react-icons for the hamburger icon
import { FaTimes } from 'react-icons/fa';  // Using react-icons for the close icon
import PageThree from './Components/Page-3';
import PageFour from './Components/Page-4';
import PageFive from './Components/Page-5';
import PageSix from './Components/Page-6';
function App() {
   // Define additional inline styles
   const buttonStyle = {
    backgroundColor: 'red',
   
  };
   // State to manage modal visibility
   const [showModal, setShowModal] = useState(false);

   // Function to toggle the modal
   const handleShow = () => setShowModal(true);
   const handleClose = () => setShowModal(false);
  return (
    <>
      <Router>
      <div className="d-flex flex-column min-vh-100 app-container">
   
    <Navbar expand="lg" className='bg-body-tertiarry'>
      <Nav className="mx-auto">
          <Navbar.Brand as={NavLink} to="/home">
            <img
              src={logo}
              alt="Logo"
              className="logo"
            />
          </Navbar.Brand>
         </Nav>
        <Button variant="link" onClick={handleShow} className='ms-button' ><FaBars size={30} /></Button>
    </Navbar> 

      <Container fluid className="flex-grow-1 my-15">
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/home" element={<Main />} />
         <Route path="/contact" element={<Contact />} />
          <Route path="/page" element={<Page />} />
          <Route path="/page-1" element={<PageOne />} />
          <Route path="/page-3" element={<PageThree />} />
          <Route path="/page-4" element={<PageFour />} />
          <Route path="/page-5" element={<PageFive />} /> 
          <Route path="/page-6" element={<PageSix />} />
       </Routes>
      </Container>
    

     {/* Navbar */}
 

      {/* Modal with menu items */}
      <Modal show={showModal} onHide={handleClose} centered fullscreen={true} >
      <Modal.Header >
        <div style={{ marginLeft: 'auto' }}>
          {/* Your content here */}
           <FaTimes onClick={handleClose} size={30} style={{ cursor: 'pointer',marginLeft: 'auto' }} />
        </div>
        </Modal.Header>

        <Modal.Body>
          <Nav className="flex-column">
         <br/> <br/> <br/>
            <Nav.Link as={NavLink}  to="/home" className="nav-link-spacing"  onClick={handleClose}>HOME</Nav.Link>
            <Nav.Link  as={NavLink} to="/portfolio" className="nav-link-spacing"  onClick={handleClose}>PORTFOLIO</Nav.Link>
            <Nav.Link as={NavLink}  to="/about"className="nav-link-spacing"  onClick={handleClose}>ABOUT</Nav.Link>
            <Nav.Link as={NavLink} to ="/contact"className="nav-link-spacing"  onClick={handleClose}>CONTACT</Nav.Link>
          </Nav>
        </Modal.Body>
      </Modal>
    
    </div>
      
    </Router>
    </>    
    
  );
}

export default App;
