import React from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";


const Main = () => {
 


 

  return (
    <>
      <>
        <Container className="d-flex justify-content-center align-items-center vh-101">
          <br />
          <Row className="w-100">
            <Col className="d-flex justify-content-center">
              <div className="centered-div">
              <video
             className="video-responsive"
             loop
          autoPlay
          playsinline
          muted={true} // Allows the video to play with sound
          controls // Optional, if you want to give users play/pause controls
          style={{ cursor: 'pointer' }} // Optional, visual feedback
          >
                  <source src="/Videos/Home_page.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
        </Container>

    
      </>
    </>
  );
};

export default Main;
