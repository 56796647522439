import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import image1 from "../Images/Portfolio-2.png";
import image2 from "../Images/Portfolio-2.png";
import video from "../Images/portfolio1.mp4";
import { Link } from "react-router-dom";
const Page = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger the animation when the component mounts
    setAnimate(true);
  }, []);
  const captions = [
    "A heartwarming journey of a teenager following his heart. This beautiful short film portrays some of life’s great purposes  on the backdrop of picturesque landscapes of New Zealand.",
    "Caption for Image 2",
  ];
  const castMembers = [
    "Cast",
    "James - Olly Moore",
    "Pops - Terry Casey",
    "Young James - Ben Cordes",
    "Dad - Jonny Brasell",
  ];
  const crewMembers = [
    "Crew",
    "Director – Clint Thambi ",
    "Camera Crew – Nishan Lama and Claudia Jones ",
    "Audio Chief – Journey Karaitiana ",
    "Best Boy – Joshua Mconie",
    "Location Guide – Abby Dodd "
  ];

  return (
    <>
      <Container className="parallexScroll">
        <Link to ="/portfolio">&larr;</Link>
        <div className={`gallery ${animate ? "zoom-in" : ""}`}>
          {[image1, video, image2].map((item, index) => (
            <div className="pinned-image" key={index}>
              {index === 1 ? (
                <video
                  className="video-responsive"
                  loop
                  autoPlay
                  playsinline
                  muted={true} // Allows the video to play with sound
                  controls // Optional, if you want to give users play/pause controls
                  style={{ cursor: "pointer" }}
                >
                  <source
                    src="../Videos/portfolio1.mp4"
                    type="video/mp4"
                  />
                </video> // Video element for second item
              ) : (
                <img
                  src={item}
                  alt={`Item ${index + 1}`}
                  className="img-fluid w-100"
                />
              )}
              {index !== 1 && index !== 2 && (
                <div className="caption">{captions[index]}</div>
              )}
              {/* Third Slide: Cast Section */}
              {index === 2 && (
                <div className="caption">
                  
                  <div className="castCrewContainer">
                    {/* Cast Section */}
                    <div className="castSection">
                      {castMembers.map((member, idx) => (
                        <h6 key={idx}>{member}</h6>
                      ))}
                    </div>

                    {/* Crew Section */}
                    <div className="crewSection">
                      {crewMembers.map((member, idx) => (
                        <h6 key={idx}>{member}</h6>
                      ))}
                    </div>
                </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Page;
