import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  } from "react-icons/fa";
import emailjs from "emailjs-com";
const Contact = () => {
  const [formData, setFormData] = useState({
    to_name: "",
    lastName: "",
    from_name: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_js1chue", // Replace with your EmailJS Service ID
        "template_hjqddys", // Replace with your EmailJS Template ID
        formData,
        "-4Q4sdfVet8oemQzE" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          console.log("response", response);
          alert("Message sent successfully!");
          setFormData({
            to_name: "",
            lastName: "",
            from_name: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send message", error);
        }
      );
  };

  return (
    <>
      <Container className="justify-content-center align-items-center">
        <Row className="vh-10">
          <Col md={6}>
            <div className="mb-31">
              <p>
                Zak Mulligan is a cinematographer known for his work in film and
                television. His film We The Animals premiered at Sundance 2018
                and was nominated for five independent spirit Awards including
                Best Cinematography. His other work includes the HBO series The
                Outsider based on a novel by Stephen King starring Jason Bateman
                and Ben Mendelsohn and the film obselidia which premiered at
                Sundance 2010 where he was honored with the Excellence in
                cinematography award. His latest film, Hustle, staring Adam
                Sandler sees Muligan paired again with Director Jeremiah Zagar.
              </p>
            </div>
            <div className="mt-4 d-flex justify-content-center">
              <p>+1.917.991.1412 | me@zakmulligan.com</p>
            </div>
            <div className="mt-4 d-flex justify-content-center">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FaFacebook size={30} color="#a17c0c" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FaLinkedin size={30} color="#a17c0c" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3"
              >
                <FaInstagram size={30} color="#a17c0c" />
              </a>
            </div>
          </Col>
          <Col md={{ span: 6 }}>
            <Form onSubmit={handleSubmit}>
              {/* Row for First Name and Last Name */}
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_name"
                      value={formData.to_name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Enter Last Name"
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Row for Email Address */}
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleChange}
                  placeholder="Enter Email"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formTextBox">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Enter your message"
                />
              </Form.Group>
              {/* Submit Button */}
              <Button variant="secondary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
