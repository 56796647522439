import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import image2 from "../Images/movie-inside2.png";
import video from "../Images/movie2.mp4";

const PageOne = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger the animation when the component mounts
    setAnimate(true);
  }, []);
  const captions = [
    "Captivating story of two lovers who believes true love never dies. " 
  ];
  const castMembers = [
    "Cast",
    "Paige – Brooke Johnston",
    "Riley – Sarah-Grace Thompson  ",
  ];
  const crewMembers = [
    "Crew",
    "Director – Clint Thambi ",
    "Screenplay – Claudia Jones   ",
    "Producer – Augustine George Akkara   ",
    "Assistant Director – Nishan Lama  ",
    "Assistant Director – Claudia Jones ",
    "Cinematographer – Clint Thambi ",
    "Cinematographer – Clint Thambi" , 
    "Sound Design – Nishan Lama", 
    "Bestboy – Joshua McOnie" , 
    "Costumes – Claudia Jones"
];

  return (
    <>
      <Container className="parallexScroll">
        <div className={`gallery ${animate ? "zoom-in" : ""}`}>
          {[image2, video, image2].map((item, index) => (
            <div className="pinned-image" key={index}>
              {index === 1 ? (
                <video
                  className="video-responsive"
                  loop
                  autoPlay
                  playsinline
                  muted={true} // Allows the video to play with sound
                  controls // Optional, if you want to give users play/pause controls
                  style={{ cursor: "pointer" }}
                >
                  <source
                    src="../Videos/movie-2.mp4"
                    type="video/mp4"
                  />
                </video> // Video element for second item
              ) : (
                <img
                  src={item}
                  alt={`Item ${index + 1}`}
                  className="img-fluid w-100"
                />
              )}
               {index !== 1 && index !== 2 && (
                <div className="caption">{captions[index]}</div>
              )}
              {/* Third Slide: Cast Section */}
              {index === 2 && (
                <div className="caption">
                  
                  <div className="castCrewContainer">
                    {/* Cast Section */}
                    <div className="castSection">
                      {castMembers.map((member, idx) => (
                        <h6 key={idx}>{member}</h6>
                      ))}
                    </div>

                    {/* Crew Section */}
                    <div className="crewSection">
                      {crewMembers.map((member, idx) => (
                        <h6 key={idx}>{member}</h6>
                      ))}
                    </div>
                </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default PageOne;
